import React from "react"
import "./sara_graphheader.css"

export default function GraphHeader({title, buttons}) {

    buttons = buttons.sort((l1, l2) => l2[0].length - l1[0].length)

    return (
        <div className="GraphHeader">
            <div className="GraphHeaderTitle">{title}</div>
            <div className="GraphHeaderButtons">
                {buttons.map((value, index) => (
                    <SimpleSingleSelect
                        key={index}
                        options={value[0]}
                        selectedOption={value[1]}
                        clickHandler={value[2]}
                    />
                ))}
                <div className="GraphHeaderPhantomTitle">{title}</div>
            </div>
        </div>
    )
}

function SimpleSingleSelect({options, selectedOption, clickHandler}) {

    const lengthOfLongestWord = Math.max(...options.map(str => str.length))

    return (
        <div className="SimpleSingleSelect">
            {options.map((option, index) => (
                <button
                    key={index}
                    className={"SimpleSingleSelectButton "+(option==selectedOption ? "Y" : "N")}
                    onClick={() => clickHandler(option)}
                    style={{"--maxLetters": lengthOfLongestWord}}
                >
                    {option}
                </button>
            ))}
        </div>
    )
}