import React from 'react';
import "./sara_checkboxdropdown.css"

export default function SingleCheckboxMenu({coverName, possibleOptions, selectedOptions, setSelectedOptions}){

    const [isOpen, setIsOpen] = React.useState(false);

    const handleChange = (option, isCurrentlySelected) => {
        if (isCurrentlySelected) {
            setSelectedOptions(selectedOptions.filter((o) => o !== option))
        } else {
            setSelectedOptions([...selectedOptions, option])
        }
    }

    const handleAllChange = () => {
        if (possibleOptions.length == selectedOptions.length) {
            setSelectedOptions([])
        } else {
            setSelectedOptions(possibleOptions)
        }
    }

    return (
        <div className="CheckboxMenu">
            <button 
                className="FilterButton"
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOptions.length} {selectedOptions.length == 1 ? coverName[0] : coverName[1]}
            </button>
            {isOpen && (
                <button 
                    className="SelectAllButton"
                    onClick={() => handleAllChange()}>
                    {possibleOptions.length == selectedOptions.length ? "Clear All" : "Select All"}
                </button>
            )}
            {isOpen && (
            possibleOptions.map((value, index) => (
                <CheckboxBar
                    key={index}
                    identity={value}
                    isSelected={selectedOptions.includes(value)}
                    handleChange={handleChange}
                />
            )))}
        </div>
    )
}

function CheckboxBar({identity, isSelected, handleChange}) {
    return (
        <div className="CheckboxBar">
            <label className="CheckboxLabelContainer">
                <input
                    className="Checkbox"
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => handleChange(identity, isSelected)}
                />
                <div className="FakeCheckbox">
                    <div className="FakeCheckboxSquare"></div>
                    <div className="FakeCheckboxCheck">
                        <svg
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d={"M 1,4.5 L 4,7.5 L 9,2.5"}
                                fill="none"
                                stroke={"green"}
                                strokeWidth="1"
                            />
                        </svg>
                    </div>
                </div>
                <div className="CheckboxLabel">
                    {identity}
                </div>
            </label>
        </div>
    )
}