import "./App.css"
import React from 'react';
import ColorfulHeader from "./Header/ColorfulHeader"
import SaraReport from './Reports/Market Capitalization/sara';

export default function App() {
    return (
        <div className="AppContainer">
            <ColorfulHeader/>
            <SaraReport/>
        </div>
    )
}