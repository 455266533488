import "./sara_piechart.css"
import React from "react"
import GraphHeader from "./Utils/sara_graphheader"

export default function InteractivePieChart({sizes, distinctors, colors, title}) {

    const [pieChartDistinctor, setPieChartDistinctor] = React.useState("Sector")

    const m = Object.keys(sizes).length
    if (m == 0) {return}
    const n = Object.values(sizes)[0].length

    const handleDistinctorChange = (option) => {
        if (option != pieChartDistinctor) {
            setPieChartDistinctor(option)
        }
    }

    var groupedSizes = {}

    //we group the sizes by their distinctor so that {"industry": 25000}
    for (const [key, size] of Object.entries(sizes)) {
        const group = distinctors[pieChartDistinctor][key]     
        if (group in groupedSizes) {
            if (!isNaN(size)) {
                groupedSizes[group] += size
            }
        } else {
            groupedSizes[group] = isNaN(size) ? 0 : size
        }
    }

    var buttonList = []
    if (Object.keys(distinctors).length > 1) {
        buttonList.push([Object.keys(distinctors), pieChartDistinctor, handleDistinctorChange])
    }

    const getOrder = (k1, k2) => {
        const i1 = Object.keys(colors[pieChartDistinctor]).indexOf(k1)
        const i2 = Object.keys(colors[pieChartDistinctor]).indexOf(k2)
        if (i1 === -1) return -1
        if (i2 === -1) return 1
        return i2 - i1
    }
    groupedSizes = Object.fromEntries(Object.keys(groupedSizes).sort((k1, k2) => getOrder(k1, k2)).map(k => [k, groupedSizes[k]]))

    return (
        <div className="InteractiveGraph" style={{height: "inherit"}}> {/* i am unsure if this is right */}
            <GraphHeader
                title={title}
                buttons={buttonList}
            />
            <div className="GraphBody">
                <PieChart
                    values={Object.values(groupedSizes)}
                    colors={Object.keys(groupedSizes).map(key => colors[pieChartDistinctor][key])}
                />
            </div>
        </div>
    )

}

function PieChart ({values, colors, labels}) {
    console.log(values)

    //calculating a proportional running sum of angles
    const sum = values.reduce((acc, num) => acc + num, 0)
    const cumulativeAngles = values.reduce(
        (acc, value) => {
            const lastAngle = acc[acc.length - 1];
            const newAngle = lastAngle + value / sum * 360;
            return [...acc, newAngle];
        },
        [0]
    );

    console.log(cumulativeAngles)

    return (
        <div className="SimplePieChart">
            <svg width="100%" height="100%" viewBox="0 0 100 100">
                {values.map((value, index) => (
                <PieSlice
                    key={index}
                    startAngle={cumulativeAngles[index]}
                    endAngle={cumulativeAngles[index + 1]}
                    color={colors[index]}
                    //label={labels[index]}
                />
                ))}
            </svg>
        </div>
    )
}

function PieSlice({startAngle, endAngle, color}) {
    if (endAngle - startAngle > 359.999) {endAngle = endAngle - 0.001} //full circle 

    const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;
    const radius = 50;

    const start = {
      x: radius + radius * Math.cos((startAngle * Math.PI) / 180),
      y: radius + radius * Math.sin((startAngle * Math.PI) / 180)
    };
    const end = {
      x: radius + radius * Math.cos((endAngle * Math.PI) / 180),
      y: radius + radius * Math.sin((endAngle * Math.PI) / 180)
    };
  
    const pathData = [
      `M ${radius} ${radius}`,
      `L ${start.x} ${start.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}`,
      'Z'
    ].join(' ');
  
    return (
        <path d={pathData} fill={color} />
    );
}

