//given a list of numbers, returns a svg-path
export function createPath(values, drawZeroes) {
    const n = values.length;
    let pathData = "";

    // Calculate the x and y coordinates for each point based on the values list
    values.forEach((value, index) => {
        if (drawZeroes || value>0) {
            const x = index / (n - 1);  // x is evenly spaced along the x-axis
            const y = 1 - value;  // y is inverted because SVG y=0 is at the top
            pathData += `L ${x},${y} `;
        }
    });
    
    return pathData;
}