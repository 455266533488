//SAME ORDER ALSO FOR CHECKBOX MENU
export const sectorColorProxy = new Proxy(
    {
        "Industrials": "rgb(24, 119, 67)",
        "Financial Services": "rgb(127, 186, 69)",
        "Consumer Cyclical": "rgb(243, 219, 66)",
        "Technology": "rgb(251, 148, 45)",
        "Healthcare": "rgb(212, 52, 52)",
        "Consumer Defensive": "rgb(116, 49, 137)",
        "Basic Materials": "rgb(37, 86, 200)",
        "Utilities": "rgb(71, 187, 213)",
        "Real Estate": "rgb(140, 140, 140)",
        "Communication Services": "rgb(80, 80, 80)",
        "Energy": "rgb(40,40,40)"
    }, 
    {get: (target, key) => key in target ? target[key] : "black"}
)

export const countryColorProxy = new Proxy(
    {
        "United States":    "rgb(178,34,52)",
        "United Kingdom":   "rgb(255,255,255)",
        "France":           "rgb(70,70,172)",
        "Germany":          "rgb(35,35,35)",
        "Sweden":           "rgb(255,201,14)",
        "Switzerland":      "rgb(255,91,91)",
        "Netherlands":      "rgb(255,127,39)",
        "Italy":            "rgb(31,160,70)",
        "Denmark":          "rgb(159,39,36)",
        "Spain":            "rgb(230,206,40)",
        "Finnland":         "rgb(108,221,221)",
        "Ireland":          "rgb(74,181,74)",
        "Belgium":          "rgb(112,112,46)",
        "Norway":           "rgb(48,48,207)",
        "Poland":           "rgb(235,150,150)",
        "Austria":          "rgb(89,36,36)",
        "Portugal":         "rgb(70,210,73)",
    }, 
    {get: (target, key) => key in target ? target[key] : "gray"}
)

export function getColorRange(type, cutoff, values) {
    if (type=="Performance threefold") {
        return(values.map(value => value > 0.05 ? "green" : (value < -0.05 ? "red" : "white")))
    }
    if (type=="Performance") {
        let green = [24, 119, 67]
        let white = [235,235,235]
        let red = [212, 52, 52]

        return(values.map(value => value > 0 ? fadeColor(green,white,value/cutoff) : fadeColor(red,white,value/-cutoff)))
    }

    return(values.map(value => "rgb(80,80,80)"))
}

//expects two lists of three numbers each
//returns a string rgb()
function fadeColor(colorA, colorB, shareA) {

    shareA = (shareA > 1) ? 1 : ((shareA < 0) ? 0 : shareA)  //cutoff overfade

    let mixedColorList = colorA.map((value, index) => (value*shareA + colorB[index]*(1-shareA)))
    
    return "rgb("+mixedColorList[0]+", "+mixedColorList[1]+", "+mixedColorList[2]+")"
}