import React from "react"
import "./sara_barchart.css"
import GraphHeader from "./Utils/sara_graphheader"

function identifyDataStructure(dataStructure) {
    //if (typeof(dataStructure)=="object") {}
    return ("string->number")
}

export default function InteractiveDoubleBarChart({values, order, labels, unit, limit, title}) { //limit bars, group rest

    //console.log("rendering InteractiveDoubleBarChart")
    //console.log(values)
    //console.log(order)

    //intro
    //if (identifyDataStructure(values) == "empty") {return(<div>There is no data.</div>)}
    //if (identifyDataStructure(values) == "string->string->number") {}
    //if (identifyDataStructure(values) == "string->number") {values={"default": values}}
    //if (identifyDataStructure(order) == "string->number") {order={"default": order}}
    //if (identifyDataStructure(labels) == "string->number") {labels={"default": labels}}

    const [valuesState, setValuesState] = React.useState(Object.keys(values)[0]) //absolute / percentage
    const [orderState, setOrderState] = React.useState(Object.keys(order)[0])
    //const [labelsState, setLabelsState] = React.useState(labels.keys()[0])

    const handleValuesChange = (option) => {
        if (option != valuesState) {
            setValuesState(option)
            keyOrderMapping = getMapping()
        }
    }

    const handleOrderChange = (option) => {
        if (option != orderState) {
            setOrderState(option)
            keyOrderMapping = getMapping()
        }
    }

    const getMapping = () => {
        if (order[orderState]=="@VALUES") {
            return (Object.keys(values[valuesState]).filter(key => !isNaN(values[valuesState][key])).sort((k1, k2) => values[valuesState][k2] - values[valuesState][k1]).slice(0,limit))
        }
        if (order[orderState]=="@-VALUES") {
            return (Object.keys(values[valuesState]).filter(key => !isNaN(values[valuesState][key])).sort((k1, k2) => values[valuesState][k1] - values[valuesState][k2]).slice(0,limit)
                .sort((k1, k2) => values[valuesState][k2] - values[valuesState][k1]))
        }
        if (order[orderState]=="@VALUES&-VALUES") {
            return (Object.keys(values[valuesState]).filter(key => !isNaN(values[valuesState][key])).sort((k1, k2) => values[valuesState][k2] - values[valuesState][k1]).slice(0,limit/2)
                .concat(Object.keys(values[valuesState]).filter(key => !isNaN(values[valuesState][key])).sort((k1, k2) => values[valuesState][k1] - values[valuesState][k2]).slice(0,limit/2)
                .sort((k1, k2) => values[valuesState][k2] - values[valuesState][k1])))
        }
        if (identifyDataStructure(order[orderState]) == "string->number") {
            return (Object.keys(values[valuesState]).filter(key => !isNaN(order[orderState][key])).sort((k1, k2) => order[orderState][k2] - order[orderState][k1]).slice(0,limit))
        }
        return []
    } 

    //[key, key, key]
    var keyOrderMapping = getMapping()
    //console.log(keyOrderMapping)
    //console.log(orderState)
    
    var buttonList = []
    if (Object.keys(values).length > 1) {
        buttonList.push([Object.keys(values), valuesState, handleValuesChange])
    }
    if (Object.keys(order).length > 1) {
        buttonList.push([Object.keys(order), orderState, handleOrderChange])
    }

    if (keyOrderMapping.length == 0) {return} //EXIT CONDITION

    return (
        <div className="InteractiveGraph">
            <GraphHeader
                title={title}
                buttons={buttonList}
            />
            <div className="GraphBody">
                <TwoSideBarChart
                    values={keyOrderMapping.map((v, i) => values[valuesState][v])}
                    //names={keyOrderMapping.map((v, i) => labels[labelsState][v])}
                    names={keyOrderMapping.map((v, i) => labels[v])}
                    unit={unit[valuesState]}
                />
            </div>
        </div>
    )
}

function TwoSideBarChart({values, names, unit}) {

    const maximum = Math.max(0, Math.max(...values))
    const minimum = Math.min(0, Math.min(...values))
    const wingspan = maximum - minimum

    return (
        <div className="TwoSideBarChart"
            style={{
                "--widthA": Math.abs(minimum/wingspan),
                "--widthB": maximum/wingspan,
            }}
        >
            <BarChartSide
                sideOfAxis={"right"}
                levels={values.map((value, index) => (value<0 ? value : names[index]))}
                maxBarsize={minimum}
                unit={unit}
            />
            <div className="TwoSideBarChartAxis"></div>
            <BarChartSide
                sideOfAxis={"left"}
                levels={values.map((value, index) => (value<0 ? names[index] : value))}
                maxBarsize={maximum}
                unit={unit}
            />
        </div>)
}

function BarChartSide({sideOfAxis, levels, maxBarsize, unit}) {
    return(
        <div className="BarChartSide">
            {levels.map((value, index) => (
                <BarChartLevel
                    key={index}
                    sideOfAxis={sideOfAxis}
                    value={value}
                    maxBarsize={maxBarsize}
                    unit={unit}
                />
            ))}
        </div>
    )
}

function BarChartLevel({sideOfAxis, value, maxBarsize, unit}) {
    if (typeof(value)=="number") {
        if (sideOfAxis=="left") {
            return (
                <div className="BarChartLevel AxisOnLeft">
                    <BarChartBar
                        width={value/maxBarsize}
                        side={"Positive"}
                    />
                    <BarChartNumber
                        number={value}
                        unit={unit}
                    />
                </div>
            )
        } else if (sideOfAxis=="right") {
            return (
                <div className="BarChartLevel AxisOnRight">
                    <BarChartBar
                        width={value/maxBarsize}
                        side={"Negative"}
                    />
                    <BarChartNumber
                        number={value}
                        unit={unit}
                    />
                </div>
            )
        }
    } else {
        return (
            <div className={"BarChartLevel"+(sideOfAxis=="right" ? " AxisOnRight" : " AxisOnLeft")}>
                <BarChartName
                    name={value}
                />
            </div>
        )
    }
}

function BarChartBar({width, side}) {
    return (
        <div 
            className={"BarChartBar "+side}
            style={{"--w": width}}
        ></div>
    )
}
function BarChartNumber({number, unit}) {
    let text = number
    if (unit == "percentage") {text = (number>0 ? "+" : "")+Math.round(number*100)+" %"}
    if (unit == "dollar") {text = (number>0 ? "+" : "")+"$"+styleMoney(number)}
    return (
        <div className="BarChartNumber">{text}</div>
    )

}
function BarChartName({name}) {
    return (
        <div className="BarChartName">{name}</div>
    )
}

function styleMoney(number) {
    if (typeof(number)=="number") {
        if (Math.abs(number) > 1000000000000) {return (Math.round(number/1000000000000)+"T")}
        if (Math.abs(number) > 1000000000) {return (Math.round(number/1000000000)+"B")}
        if (Math.abs(number) > 1000000) {return (Math.round(number/1000000)+"M")}
        if (Math.abs(number) > 1000) {return (Math.round(number/1000)+"K")}
        return (number)
    }
}