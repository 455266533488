import React from 'react';
import "./sara_doubleslider.css"

export default function DoubleSlider({possibleSpan, selectedSpan, setSelectedSpan}) {

    const [minValue, setMinValue] = React.useState(selectedSpan[0])
    const [maxValue, setMaxValue] = React.useState(selectedSpan[1])
    const spanBuffer = Math.round((possibleSpan[1] - possibleSpan[0]) * 0.1) //magic number for styling !

    const handleChangeMin = (e) => {
        //console.log(e.target.value)
        if (maxValue < Number(e.target.value) + spanBuffer) {
            setMaxValue(Number(e.target.value) + spanBuffer)
        }
        setMinValue(Number(e.target.value))
    }
    const handleChangeMax = (e) => {
        //console.log(e.target.value)
        if (minValue > Number(e.target.value) - spanBuffer) {
            setMinValue(Number(e.target.value) - spanBuffer)
        }
        setMaxValue(Number(e.target.value))
    }

    //we only update the SelectedSpan after a delay
    React.useEffect(() => {
        const handler = setTimeout(() => {
            setSelectedSpan([minValue, maxValue]);
        }, 100);
    
        // Cleanup function to clear the timeout if minValue/maxValue changes again before the delay ends
        return () => {
          clearTimeout(handler);
        };
    }, [minValue, maxValue]); // dependencies: according to ESLint we need onExternalChange here too 
    

    return (
        <div className="DoubleSlider">
            <input 
                className="RangeInput Max"
                type="range"
                min={possibleSpan[0]+spanBuffer}
                max={possibleSpan[1]}
                step="1"
                value={maxValue}
                onChange={handleChangeMax}
            />
            <input 
                className="RangeInput Min"
                type="range"
                min={possibleSpan[0]}
                max={possibleSpan[1]-spanBuffer}
                step="1"
                value={minValue}
                onChange={handleChangeMin}
            />
            <div
                className="SliderThumb"
                style={{"--x": (minValue-possibleSpan[0]) / (possibleSpan[1]-possibleSpan[0])}}
            ></div>
            <div
                className="SliderThumb"
                style={{"--x": (maxValue-possibleSpan[0]) / (possibleSpan[1]-possibleSpan[0])}}
            ></div>
            <div
                className="SliderLabel"
                style={{"--x": (minValue-possibleSpan[0]) / (possibleSpan[1]-possibleSpan[0])}}
            >{minValue}</div>
            <div
                className="SliderLabel"
                style={{"--x": (maxValue-possibleSpan[0]) / (possibleSpan[1]-possibleSpan[0])}}
            >{maxValue}</div>
            <div
                className="SelectedSpan"
                style={{
                    "--x0": (minValue-possibleSpan[0]) / (possibleSpan[1]-possibleSpan[0]),
                    "--x1": (maxValue-possibleSpan[0]) / (possibleSpan[1]-possibleSpan[0]),
                }}
            ></div>
            <div className="FullSpan"></div>
        </div>
    )
}