import React from 'react';
import "./ColorfulHeader.css"

export default function ColorfulHeader() {
    return (
        <div className="WebsiteHeader">
            <div className="LogoContainer">
                <a href="https://www.colorful-data.com" className="LogoLink">
                    <ColorfulLogo/>
                </a>
            </div>
            <div className="SettingsContainer"></div>            
        </div>
    )
}

function ColorfulLogo() {
    return (
        <div className="ColorfulLogo">
            <span className="ColorfulLetter A">c</span>
            <span className="ColorfulLetter B">o</span>
            <span className="ColorfulLetter C">l</span>
            <span className="ColorfulLetter D">o</span>
            <span className="ColorfulLetter E">r</span>
            <span className="ColorfulLetter F">f</span>
            <span className="ColorfulLetter G">u</span>
            <span className="ColorfulLetter H">l</span>
            <span className="ColorfulLetter I">-data.com</span>
        </div>
    ) 
}