import "./sara_treemap.css"
import React from "react"
import GraphHeader from "./Utils/sara_graphheader"

//creates a TreeMap with buttons to change color scheme
//sizes         [1500,800,933,...]
//colorOptions  {"optionA": ["rgb()", ...]}
export default function InteractiveTreeMap({sizes, labels, colorOptions, title}) {

    //console.log("INTERACTIVE TREE MAP")
    //console.log(sizes)
    //console.log(labels)
    //console.log(colorOptions)

    const [treeMapColorOption, setTreeMapColorOption] = React.useState("Country")

    if (sizes.filter(num => !isNaN(num)).length == 0) {return} //EXIT CONDITION

    const handleOptionChange = (option) => {
        if (option != treeMapColorOption) {
            setTreeMapColorOption(option)
        }
    }

    return (
        <div className="InteractiveGraph">
            <GraphHeader
                title={title}
                buttons={[[Object.keys(colorOptions), treeMapColorOption, handleOptionChange]]}
            />
            <div className="GraphBody">
                <TreeMap
                    sizes={sizes} 
                    colors={colorOptions[treeMapColorOption]}
                    labels={labels}
                />
            </div>
        </div>
    )
}

//input: list of sizes, colors, labels
function TreeMap({sizes, colors, labels}) {
    
    //console.log("TREE MAP")
    //console.log(sizes)
    //console.log(colors)
    //console.log(labels)

    const positions = React.useMemo(() => {
        return TreeMapCalc(sizes)
    }, [sizes])
    
    return (
        <div className="TreeMap">
            {positions.map((value, index) => (
                <Rectangle 
                    key={index} 
                    layout={value}
                    label={labels[index]}
                    color={colors[index]}
                />
            ))}
        </div>
    )  

}

function Rectangle({layout, label, color}) {
    return (
        <div 
            className="Rectangle"
            style={{
                "--c": color,
                "--x": layout[0],
                "--y": layout[1],
                "--w": layout[2],
                "--h": layout[3],
            }}
        ><p>{(layout[2]>0.01 && layout[3]>0.005)? label : ""}</p></div>
    )
}

//returns a list of [x,y,h,w] indicating the positions of the individual areas
function TreeMapCalc(values) {
    
    //console.log("calculations for treemap")
    //console.log(values)
    values = values.map(x => isNaN(x) ? 0 : x)

    const sum = values.reduce((a, b) => a + b, 0)
    const normalizedValues = values.map(num => num / sum)
    const result = new Array(values.length)
    const limit = 0.4

    var sum_of_remaining = 1
    var x = 0;
    var y = 0;

    while (sum_of_remaining > 0.00001) {

        var indices_of_added = []
        var areas_of_added = []
        var sum_of_added = 0

        var upright = x > y
        
        while (sum_of_added/sum_of_remaining < limit)  {
            
            //find biggest remaining
            const max = index_of_max(normalizedValues)
            indices_of_added.push(max)
            areas_of_added.push(normalizedValues[max])
            sum_of_added += normalizedValues[max]
            normalizedValues[max] = 0
            
            //once they are squares we stop collecting the biggest
            if (x > y && (1-x)/indices_of_added.length < sum_of_added/(1-x)) {break}
            if (y >= x && (1-y)/indices_of_added.length < sum_of_added/(1-y)) {break}
        }

        if (x > y) {
            //we create a horizontal row
            const width_of_slice = 1-x
            const height_of_slice = sum_of_added / width_of_slice
            let rolling_x = x
            for(let i=0; i<indices_of_added.length; i++) {
                let width_of_segment = areas_of_added[i]/height_of_slice
                result[indices_of_added[i]] = [rolling_x,y,width_of_segment,height_of_slice]
                rolling_x += width_of_segment
            }
            y += height_of_slice
        } else {
            //we create a vertical column
            const height_of_slice = 1-y
            const width_of_slice = sum_of_added / height_of_slice
            let rolling_y = y
            for(let i=0; i<indices_of_added.length; i++) {
                let height_of_segment = areas_of_added[i]/width_of_slice
                result[indices_of_added[i]] = [x,rolling_y,width_of_slice,height_of_segment]
                rolling_y += height_of_segment
            }
            x += width_of_slice
        }

        sum_of_remaining -= sum_of_added
        sum_of_added = 0
        indices_of_added = []
        areas_of_added = []
    }

    //console.log("RESULT OF TREEMAP CALC")
    //console.log(result)
    return result
}

function index_of_max(arr) {
    let maxIndex = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] > arr[maxIndex]) {
            maxIndex = i;
        }
    }
    return maxIndex
}