export const fetchData = async(setTickers, setTickerDetails, setPrices, setMarketCaps, setPerformances) => {
    fetch("EU_US_monthly.csv")
        .then(response => {
            if (!response.ok) {
                throw new Error('File not found or cannot be accessed');
            }
            return response.text(); // Read the file content as plain text
        })
        .then(text => {
            process_csv_single(text, setTickers, setTickerDetails, setPrices, setMarketCaps, setPerformances)
        })
        .catch(error => {
            //console.log(error)
        });
}

function process_csv_single(text, setTickers, setTickerDetails, setPrices, setMarketCaps, setPerformances) {
    //number of ticker details (without shares outstanding)
    const n_td = 6

    const rows = text.split('\n');
    const header = rows[0].split(',')
    const details = header.slice(1,n_td+1)
    const dates = header.slice(n_td+2)

    var tickers = []
    var tickerDetails = {}
    var prices = {}
    var marketCaps = {}
    var performances = {}
    //todo: add the dates to each dict ?

    rows.slice(1).forEach(row => {
        const columns = row.split(',')
        if (row.length < n_td+2) {return}

        //ticker (key)
        let ticker = columns[0]
        if (["MB.MI", "UNI.MI", "SPM.MI", "REC.MI", "BMPS.MI", "IP.MI", "UCG.MI","A5G.IR", "MOWI.OL"].includes(ticker)) {
            return // CORRUPT DATA: tickers with ultra high values about 20 years back
        }
        tickers.push(ticker)

        //ticker details
        let helper = {}
        columns.slice(1,n_td+1).map((value, index) => {
            helper[details[index]] = value
        })
        tickerDetails[ticker] = helper

        //shares outstanding will be dropped
        let sharesOutstanding = parseFloat(columns[n_td+1])
        
        //the list of prices
        let priceHistory = columns.slice(n_td+2)
        priceHistory = priceHistory.map(str => str ==="" ? NaN : parseFloat(str)) //turning ints into floats
        priceHistory = priceHistory.map(float => float < 0 ? NaN : float) //negative values as NaN
        if (ticker.includes(".L")) {
            priceHistory = priceHistory.map(float => float/100) //divide english by 100
        }
        prices[ticker] = priceHistory
        const n = priceHistory.length

        //the list of market caps
        let marketCapHistory = new Array(n).fill(1000)
        priceHistory.map((value, index) => {
            marketCapHistory[index] = value * sharesOutstanding //value==NaN ? NaN :
        })
        marketCaps[ticker] = marketCapHistory

        //the list of performances 
        let performanceHistory = new Array(n-1).fill(0)
        for(let i=0; i<n-1; i++) {
            performanceHistory[i] = 1 - (priceHistory[i]/priceHistory[i+1]) //priceHistory[i]=="" ? NaN : 
        }
        performances[ticker] = performanceHistory
    })

    setTickers(tickers)
    setTickerDetails(tickerDetails)
    setPrices(prices)
    setMarketCaps(marketCaps)
    setPerformances(performances)

}

export function setAuxillaryUseStates(tickerDetails, setCountries, setSectors, setSectorsIndustries) {
    //set the list of countries ordered by number of tickers
    let countryCount = {}
    for (const detailDict of Object.values(tickerDetails)) {
        countryCount[detailDict["country"]] = (countryCount[detailDict["country"]] || 0) + 1; //init 0 or increment
    }
    let sortedUniqueCountryList = Object.keys(countryCount).sort((a,b) => countryCount[b]-countryCount[a])
    setCountries(sortedUniqueCountryList)
    
    //a dict containing sectors as keys (sorted by number of tickers)
    let sectorCount = {}
    let sectorIndustries = {}
    for (const detailDict of Object.values(tickerDetails)) {
        const sector = detailDict["sector"]
        const industry = detailDict["industry"]

        //add industry to sector:industry dict
        if (sector in sectorIndustries) {
            if (!sectorIndustries[sector].includes(industry)) {
                sectorIndustries[sector].push(industry)
            }
        } else {
            sectorIndustries[sector] = [industry] //init as list
        }

        //keep count of n tickers per sector
        sectorCount[sector] = (sectorCount[sector] || 0) + 1;
    }
    //get the order of sectors and create a new dict
    let sortedUniqueSectorList = Object.keys(sectorCount).sort((a,b) => sectorCount[b]-sectorCount[a])
    let sortedSectorIndustryDict = {}
    sortedUniqueSectorList.forEach(sector => {
        sortedSectorIndustryDict[sector] = sectorIndustries[sector]
    })
    //console.log("END OF AUX")
    setSectors(sortedUniqueSectorList)
    setSectorsIndustries(sortedSectorIndustryDict)
}