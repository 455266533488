import "./sara_legend.css"
import React from "react"

export default function SimpleLegend({labels, colors}) {
    return (
        <div className="SimpleLegend">
            {labels.map((value, index) => (
                <LegendEntry
                    key={index}
                    label={value}
                    color={colors[index]}
                />
            ))}
        </div>
    )
}

function LegendEntry({label, color}) {
    return (
        <div className="LegendEntry">
            <div className="LegendIndicator"
                style={{
                    "--c": color
                }}
            ></div>
            <div className="LegendLabel">{label}</div>
        </div>
    )
}