import React from "react"
import "./sara_areachart.css"
import GraphHeader from "./Utils/sara_graphheader"
import { createPath } from "./Utils/sara_miscellaneous"

//values = {"AAPL": []}
//distinctors = {"sector": , "country"}
export default function InteractiveAreaChart({values, distinctors, colors, title}) {

    //console.log("INTERACTIVE AREA CHART: VALUES, DISTINCTORS")
    //console.log(values)
    //console.log(distinctors)

    const [areaChartDistinctor, setAreaChartDistinctor] = React.useState("Sector")
    const [areaChartScale, setAreaChartScale] =  React.useState("linear")

    const m = Object.keys(values).length
    if (m == 0) {return}
    const n = Object.values(values)[0].length

    const handleDistinctorChange = (option) => {
        if (option != areaChartDistinctor) {
            setAreaChartDistinctor(option)
        }
    }
    const handleScaleChange = (option) => {
        if (option != areaChartScale) {
            setAreaChartScale(option)
        }
    }

    var groupedValues = {}
    //var maxDict = {}

    //we group the values by their distinctor so that {"industry": [...]}
    for (const [key, timeSeries] of Object.entries(values)) {
        const group = distinctors[areaChartDistinctor][key]     
        if (group in groupedValues) {
            for (let i=0; i<timeSeries.length; i++) {           //element-wise addition
                if (!isNaN(timeSeries[i])) {
                    groupedValues[group][i] += timeSeries[i]
                }
            }
        } else {
            groupedValues[group] = timeSeries.map(value => isNaN(value) ? 0 : value)
        }
        //maxDict[key] = Math.max(...timeSeries.map(value => isNaN(value) ? 0 : value))
    }

    //element-wise sum
    var summedUpValues = new Array(n).fill(0)
    for (const timeSeries of Object.values(groupedValues)) {
        for (let i=0; i < n; i++) {
            summedUpValues[i] = summedUpValues[i] + timeSeries[i]
        }
    }
    let nonZeroMinSum = Math.min(...summedUpValues.filter(num => num > 0))
    
    if (nonZeroMinSum == Infinity) {return} //EXIT CONDITION

    //depending on Scale type, we modifiy all values
    if (areaChartScale=="100%") {
        for (const timeSeries of Object.values(groupedValues)) {
            for (let i=0; i < n; i++) {
                timeSeries[i] = timeSeries[i] / Math.max(1,summedUpValues[i])   //divide each value by max
            }
        }
    } else if (areaChartScale=="log10") {
        for (const timeSeries of Object.values(groupedValues)) {
            for (let i=0; i < n; i++) {
                timeSeries[i] = timeSeries[i] / Math.max(1,summedUpValues[i]) * (Math.log10(Math.max(1,summedUpValues[i])) - Math.log10(nonZeroMinSum)) //divide by max and mult by log(max)
            }
        }
    }

    //console.log("GROUPED VALUES")
    //console.log(groupedValues)
    
    var buttonList = []
    if (Object.keys(distinctors).length > 1) {
        buttonList.push([Object.keys(distinctors), areaChartDistinctor, handleDistinctorChange])
    }
    buttonList.push([["linear", "log10", "100%"], areaChartScale, handleScaleChange]) // 
    
    const getOrder = (k1, k2) => {
        const i1 = Object.keys(colors[areaChartDistinctor]).indexOf(k1)
        const i2 = Object.keys(colors[areaChartDistinctor]).indexOf(k2)
        //console.log(i1 + " " +i2)
        if (i1 === -1) return -1
        if (i2 === -1) return 1
        return i2 - i1
    }
    //console.log(Object.keys(groupedValues))
    groupedValues = Object.fromEntries(Object.keys(groupedValues).sort((k1, k2) => getOrder(k1, k2)).map(k => [k, groupedValues[k]]))
    //console.log(Object.keys(groupedValues))

    return (
        <div className="InteractiveGraph">
            <GraphHeader
                title={title}
                buttons={buttonList}
            />
            <div className="GraphBody">
                <AreaChart
                    values={Object.values(groupedValues)}
                    colors={Object.keys(groupedValues).map(key => colors[areaChartDistinctor][key])}
                />
            </div>
        </div>
    )
}

//values = [[],[]]
//colors = ["red", "rgb()"]
function AreaChart({values, colors}) {

    //console.log("AREA CHART: Values, Colors")
    //console.log(values)
    //console.log(colors)

    const m = values.length
    if (m == 0) {return}
    const n = values[0].length
    
    //adding up the different area heights (running sum)
    var layers = [[...values[0]]]
    for (let j = 1; j < m; j++) {
        layers.push([...layers[j-1]])
        for (let i = 0; i < n; i++) {
            layers[j][i] += values[j][i]
        }
    }

    //divide by the maximum of the highest layer to scale to a 0-1 range
    const maximum = Math.max(1, Math.max(...layers[m-1]))
    for (let j = 0; j < m; j++) {
        for (let i = 0; i < n; i++) {
            layers[j][i] = layers[j][i] / maximum
        }
    }
    
    //console.log(layers)

    //<XAxis/> if maximum < 1.0001 use percentages
    //<YAxis/>
    return (
        <div className="StackedAreaChart">
            <Areas layers={layers} colors={colors}/>
        </div>
    )
}

//layers = [[0,1,...],[1,...]]
//colors = ["green", "rgb()"]
function Areas({layers, colors}) {
    return (
        <div className="Areas">
            {layers.map((value, index) => (
                <Area 
                    key={index}
                    heights={value}
                    zOrder={layers.length-index} 
                    color={colors[index]}
                />
            ))}
        </div>
    )
}

function Area({heights, zOrder, color}) {
    //console.log("AREA:")
    //console.log(heights)

    return (
        <div className="Area" style={{zIndex: zOrder}}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 1 1"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d={"M 0,1 "+createPath(heights, true)+"L 1,1 Z"}
                    fill={color}
                    stroke={color}
                    strokeWidth="0"
                />
            </svg>
        </div>
    )
}