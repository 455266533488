import "./sara.css"
import React from 'react';
import { fetchData, setAuxillaryUseStates } from '../../Data/sara_data';
import { countryColorProxy, sectorColorProxy, getColorRange } from './sara_colors';
import SingleCheckboxMenu from '../../Input/sara_checkboxdropdown';
import DoubleSlider from '../../Input/sara_doubleslider';
import InteractiveAreaChart from '../../Charts/sara_areachart';
import InteractiveDoubleBarChart from '../../Charts/sara_barchart';
import InteractiveTreeMap from '../../Charts/sara_treemap';
import InteractivePieChart from '../../Charts/sara_piechart';
import SimpleLegend from '../../Charts/sara_legend';

export default function SaraReport() {
    //the data itself
    const [tickers, setTickers] = React.useState([]);
    const [tickerDetails, setTickerDetails] = React.useState({});
    const [prices, setPrices] = React.useState({});
    const [marketCaps, setMarketCaps] = React.useState({}); 
    const [performances, setPerformances] = React.useState({});
    
    //auxillary data for filter buttons
    //countries
    const [possibleCountries, setPossibleCountries] = React.useState([]);
    const [filteredCountries, setFilteredCountries] = React.useState([]);
    //sectors
    const [possibleSectors, setPossibleSectors] = React.useState([]);
    const [filteredSectors, setFilteredSectors] = React.useState([]);
    const [possibleSectorsIndustries, setPossibleSectorsIndustries] = React.useState({});
    const [filteredSectorsIndustries, setFilteredSectorsIndustries] = React.useState({});
    //time
    const [possibleTimespan, setPossibleTimespan] = React.useState([1962,2023]);
    const [filteredTimespan, setFilteredTimespan] = React.useState([1980,2023]);
    //tickers
    const [selectedTickers, selectTickers] = React.useState(["AAPL", "F", "NVDA", "MSFT"]);
    //mode
    const [selectedMode, selectMode] = React.useState("lin");

    //filtered data for the charts
    const [filteredTickers, setFilteredTickers] = React.useState([]);
    const [filteredMarketCaps, setFilteredMarketCaps] = React.useState({});

    //timestamp
    const [selectedTimestamp, setSelectedTimestamp] = React.useState(750);
    
    //Initial Data Loading
    React.useEffect(() => {
        fetchData(setTickers, setTickerDetails, setPrices, setMarketCaps, setPerformances);
    },      
        []  
    );

    //Auxillary Data for filter options
    React.useEffect(() => {
        setAuxillaryUseStates(tickerDetails, setPossibleCountries, setPossibleSectors, setPossibleSectorsIndustries)
    },
        [tickerDetails]
    )

    //Speacial SectorList (remove later)
    React.useEffect(() => {
        setFilteredSectors(possibleSectors)
        setFilteredCountries(["United States"])
    },
        [possibleSectors, possibleCountries]
    )

    //The filter logic
    React.useEffect(() => {
        const minIndex = (filteredTimespan[0]-possibleTimespan[0]) * 12
        const maxIndex = (filteredTimespan[1]-possibleTimespan[0]) * 12 + 11
        var filteredTickers = tickers.filter(ticker =>
            filteredCountries.includes(tickerDetails[ticker]["country"])
            && filteredSectors.includes(tickerDetails[ticker]["sector"])    
        )
        var marketCapDict = {}
        filteredTickers.forEach(ticker => {
            marketCapDict[ticker] = marketCaps[ticker].slice(minIndex,maxIndex)
        });
        setFilteredMarketCaps(marketCapDict)
        setFilteredTickers(filteredTickers)
        setSelectedTimestamp(maxIndex)
    },
        [filteredCountries, filteredSectors, filteredTimespan]
    )

    //timestamp filter logic
    React.useEffect(() => {
        
    },
        [selectedTimestamp]
    )

    const getStringOfTimestamp = (timestamp) => {
        const listOfMonths = ["January", "February", "March", "April", "May", "June", 
            "July", "August", "September", "October", "November", "December"]

        const year = possibleTimespan[0] + Math.floor(timestamp/12)
        const month = listOfMonths[timestamp % 12]

        return (month+" "+year)
    } 

    if (tickers.length==0) {return (<div>Loading Data ...</div>)}

    return (
        <div className="ReportContainer">
            <div className="FiltersContainer">
                <div className="FilterButtonContainer">
                    <SingleCheckboxMenu
                        coverName={["Country", "Countries"]}
                        possibleOptions={possibleCountries}
                        selectedOptions={filteredCountries}
                        setSelectedOptions={setFilteredCountries}
                    />
                </div>
                <div className="FilterButtonContainer">
                    <SingleCheckboxMenu
                        coverName={["Sector", "Sectors"]}
                        possibleOptions={possibleSectors}
                        selectedOptions={filteredSectors}
                        setSelectedOptions={setFilteredSectors}
                    />
                </div>
                <div className="FilterSliderContainer">
                    <DoubleSlider
                        possibleSpan={possibleTimespan}
                        selectedSpan={filteredTimespan}
                        setSelectedSpan={setFilteredTimespan}
                    />
                </div>
            </div>
            <div className="ChartsContainer">
                <div className="AreaChartContainer">
                    <InteractiveAreaChart
                        values={filteredMarketCaps}
                        //values={filteredMarketCaps}
                        //values1={filteredTickers.map(ticker => marketCaps[ticker].slice(minIndex, maxIndex))}
                        distinctors={{
                            "Country": Object.fromEntries(tickers.map(ticker => [ticker, tickerDetails[ticker]["country"]])),
                            "Sector": Object.fromEntries(tickers.map(ticker => [ticker, tickerDetails[ticker]["sector"]])),          
                        }}
                        colors={{
                            "Country": countryColorProxy,
                            "Sector": sectorColorProxy,
                        }}
                        title={"Market Capitalization"}
                    />
                </div>
                <div className="LegendContainer">
                    <SimpleLegend
                        //labels={[...new Set(filteredTickers.map(ticker => tickerDetails[ticker]["sector"]))]}
                        //colors={[...new Set(filteredTickers.map(ticker => sectorColorProxy[tickerDetails[ticker]["sector"]]))]}
                        labels={Object.keys(sectorColorProxy).filter(item => filteredTickers.map(ticker => tickerDetails[ticker]["sector"]).includes(item))}
                        colors={Object.values(sectorColorProxy).filter(item => filteredTickers.map(ticker => sectorColorProxy[tickerDetails[ticker]["sector"]]).includes(item))}
                    />
                </div>
                <div className="LegendContainer">
                    <SimpleLegend
                        //labels={[...new Set(filteredTickers.map(ticker => tickerDetails[ticker]["sector"]))]}
                        //colors={[...new Set(filteredTickers.map(ticker => sectorColorProxy[tickerDetails[ticker]["sector"]]))]}
                        labels={Object.keys(countryColorProxy).filter(item => filteredTickers.map(ticker => tickerDetails[ticker]["country"]).includes(item))}
                        colors={Object.values(countryColorProxy).filter(item => filteredTickers.map(ticker => countryColorProxy[tickerDetails[ticker]["country"]]).includes(item))}
                    />
                </div>
                <div className="TreeMapContainer">
                    <InteractiveTreeMap
                        sizes={filteredTickers.map(ticker => marketCaps[ticker][selectedTimestamp])}
                        labels={filteredTickers}
                        colorOptions={{
                            //"Individual": filteredTickers.map(ticker =>  tickerDetails[ticker]["color"]),
                            "Performance": getColorRange("Performance", 0.1, filteredTickers.map(ticker => performances[ticker][selectedTimestamp-1])),
                            "Country": filteredTickers.map(ticker => countryColorProxy[tickerDetails[ticker]["country"]]),
                            "Sector": filteredTickers.map(ticker => sectorColorProxy[tickerDetails[ticker]["sector"]]),
                        }}
                        title={"Market Capitalization in "+getStringOfTimestamp(selectedTimestamp)}
                    />
                </div>
                <div className="PieChartContainer">
                    <InteractivePieChart
                        sizes={Object.fromEntries(filteredTickers.map(ticker => [ticker, marketCaps[ticker][selectedTimestamp]]))}
                        distinctors={{
                            "Country": Object.fromEntries(tickers.map(ticker => [ticker, tickerDetails[ticker]["country"]])),
                            "Sector": Object.fromEntries(tickers.map(ticker => [ticker, tickerDetails[ticker]["sector"]])),          
                        }}
                        colors={{
                            "Country": countryColorProxy,
                            "Sector": sectorColorProxy,
                        }}
                        title={"Market Capitalization"}
                    />
                </div>
                <div className="BarChartContainer">
                    <InteractiveDoubleBarChart
                        labels={Object.fromEntries(filteredTickers.map(ticker => [ticker, ticker]))} //[ticker -> string] ?
                        values={{ //[ticker -> value]
                            "Percentage": Object.fromEntries(filteredTickers.map(ticker => [ticker, performances[ticker][selectedTimestamp-1]])),
                            "Absolute Value": Object.fromEntries(filteredTickers.map(ticker => [ticker, marketCaps[ticker][selectedTimestamp]-marketCaps[ticker][selectedTimestamp-1]])),
                        }}
                        order={{
                            "Top 10": "@VALUES",
                            "Top 5 & Bottom 5": "@VALUES&-VALUES", 
                            "Bottom 10": "@-VALUES",
                            "Largest": Object.fromEntries(filteredTickers.map(ticker => [ticker, marketCaps[ticker][selectedTimestamp]]))
                        }}
                        unit={{
                            "Percentage": "percentage",
                            "Absolute Value": "dollar",
                        }}
                        limit={10}
                        title={"Performance of Stocks in "+getStringOfTimestamp(selectedTimestamp)}
                    />
                </div>
            </div>
        </div>
    )
}
